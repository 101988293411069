<template>
  <div>
    <h2>Bemessung Zuggurt / Ringanker</h2>
    <p>Entsprechend Heft 240 wird der innere Hebelarm "z" der Scheibe bestimmt und aus dem Scheibenmoment "Md" daraus die maximale Ringankerkraft "FEd".
      Aus der oben ermittelten Zuggurtkraft "FEd" wird unter Berücksichtigung der Mindestbewehrung nach DIN 1045-1 die erforderliche Zuggurtbewehrung ermittelt.</p>
  </div>
</template>

<script>
export default {
  name: "ResultTabOneHelp"
};
</script>

<style lang="scss">
</style>